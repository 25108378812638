@import '../../node_modules/dialog-polyfill/dialog-polyfill.css';
@import './roboto.css';

.kubecost-app-body {
  background-color: #f3f3f3;
  display: flex;
  font-family: 'Roboto', sans-serif;
  margin: 0px;
  overflow-y: scroll;
}

ul {
  list-style: none;
}

.recharts-tooltip-wrapper {
  z-index: 1000;
}
