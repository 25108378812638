#health .footer {
  text-align: center;
  color: #666;
  margin: 40px 0;
}
#health table a {
  text-decoration: none;
  font-weight: normal;
}
#health .row {
  cursor: pointer;
}
#health .body-content {
  margin: 20px 30px 0 220px;
  min-width: 800px;
  max-width: 1200px;
}
#health .card-footer-button {
  float: right;
  font-size: 13px;
  color: rgb(95, 157, 226);
  font-weight: 400;
  letter-spacing: 0.13px;
  margin: 2px;
}
#health .clickable:hover {
  background-color: #eee;
  cursor: pointer;
}
#health .control-box {
  height: 50px;
  margin-bottom: 20px;
}
#health .control-box a {
  color: #666;
  float: right;
  margin-left: 8px;
}
#health .full-width {
  width: 100%;
  min-width: 800px;
}
#health .green {
  color: green;
}
#health .issue-sub {
  color: #666;
}
#health .issue-cost {
  font-size: 16px;
}
#health .mdl-chip {
  border-radius: 6px;
  padding: 0 6px;
  height: 22px;
  color: #777;
  margin: 0;
}
#health .mdl-card__title-text {
  font-size: 20px;
}
#health .mdl-badge.mdl-badge--overlap:after {
  right: -10px;
  top: -4px;
}
#health .mdl-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0;
  border-top-color: rgba(0, 0, 0, 0.12);
}
#health .more-box {
  background-color: white;
  display: none;
  padding: 6px 12px;
  position: absolute;
  left: 0;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  cursor: pointer;
}
#health .more-box a {
  color: #666;
  text-decoration: none;
}

#health .nav {
  top: 0px;
  width: 200px;
  min-height: 100vh;
  position: fixed;
  z-index: 100;
  padding-top: 8px;
}
#health .nav-item {
  text-align: left;
  height: 48px;
  cursor: pointer;
  margin: 0 8px;
  color: rgb(78, 78, 78);
}
#health .nav-item .material-icons {
  position: fixed;
  left: 16px;
}
#health .nav-item .nav-text {
  position: fixed;
  left: 52px;
  font-size: 16px;
  line-height: 52px;
}
#health .nav-item:hover {
  background-color: rgb(235, 235, 235);
}
#health .nav-item.selected {
  background-color: rgb(225, 225, 225);
  color: rgb(52, 110, 242);
}
#health .nav-icon.mdl-badge.mdl-badge--overlap:after {
  right: -4px;
  top: -5px;
  font-size: 12px;
  width: 14px;
  height: 14px;
  background-color: orange;
}
#health .switch-cluster-row:hover {
  background-color: #eee;
}
#health .smaller-cell {
  height: 24px;
  padding: 8px 12px;
}
#health .title {
  float: left;
  margin-top: 0px;
  font-size: 1.2rem;
  color: #555;
}

#health .gauge-container {
  width: 400px;
  height: 200px;
  position: relative;
  top: 100px;
  left: 50%;
  overflow: hidden;
  text-align: center;
  transform: translate(-50%, -50%);
  margin-bottom: 40px;
}

#health .gauge-a {
  z-index: 1;
  position: absolute;
  background-color: rgba(200, 200, 200, 0.3);
  width: 400px;
  height: 200px;
  top: 0%;
  border-radius: 250px 250px 0px 0px;
  display: none;
}

#health .gauge-b {
  background-color: #f3f3f3;
  z-index: 3;
  position: absolute;
  width: 250px;
  height: 125px;
  top: 75px;
  margin-left: 75px;
  margin-right: auto;
  border-radius: 250px 250px 0px 0px;
}

#health .gauge-c {
  z-index: 2;
  position: absolute;
  width: 400px;
  height: 200px;
  top: 200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0px 0px 200px 200px;
  transform-origin: center top;
  transition: all 1.3s ease-in-out;
}

#health .gauge-data {
  z-index: 4;
  color: #3cba54;
  font-size: 1.5em;
  line-height: 25px;
  position: absolute;
  width: 400px;
  height: 200px;
  top: 125px;
  margin-left: auto;
  margin-right: auto;
  transition: all 1s ease-out;
}
